html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video, h1, h2, h3,
h4, h5, .h3 {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

textarea, select, input, button { outline: none; }

*, *:after, *:before {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	margin: 0;
	padding: 0;
	list-style: none;
  text-decoration: none;
}

/* @font-face {
  font-family: 'Helvetica-Bold';
  src: url('fonts/Helvetica-Bold.eot');
  src: url('fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica-Bold.svg#Helvetica-Bold') format('svg'),
       url('fonts/Helvetica-Bold.ttf') format('truetype'),
       url('fonts/Helvetica-Bold.woff') format('woff'),
       url('fonts/Helvetica-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica-BoldOblique';
  src: url('fonts/Helvetica-BoldOblique.eot');
  src: url('fonts/Helvetica-BoldOblique.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica-BoldOblique.svg#Helvetica-BoldOblique') format('svg'),
       url('fonts/Helvetica-BoldOblique.ttf') format('truetype'),
       url('fonts/Helvetica-BoldOblique.woff') format('woff'),
       url('fonts/Helvetica-BoldOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica-Light';
  src: url('fonts/Helvetica-Light.eot');
  src: url('fonts/Helvetica-Light.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica-Light.svg#Helvetica-Light') format('svg'),
       url('fonts/Helvetica-Light.ttf') format('truetype'),
       url('fonts/Helvetica-Light.woff') format('woff'),
       url('fonts/Helvetica-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica-LightOblique';
  src: url('fonts/Helvetica-LightOblique.eot');
  src: url('fonts/Helvetica-LightOblique.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica-LightOblique.svg#Helvetica-LightOblique') format('svg'),
       url('fonts/Helvetica-LightOblique.ttf') format('truetype'),
       url('fonts/Helvetica-LightOblique.woff') format('woff'),
       url('fonts/Helvetica-LightOblique.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica-Oblique';
  src: url('fonts/Helvetica-Oblique.eot');
  src: url('fonts/Helvetica-Oblique.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica-Oblique.svg#Helvetica-Oblique') format('svg'),
       url('fonts/Helvetica-Oblique.ttf') format('truetype'),
       url('fonts/Helvetica-Oblique.woff') format('woff'),
       url('fonts/Helvetica-Oblique.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('fonts/Helvetica.eot');
  src: url('fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica.svg#Helvetica') format('svg'),
       url('fonts/Helvetica.ttf') format('truetype'),
       url('fonts/Helvetica.woff') format('woff'),
       url('fonts/Helvetica.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */

